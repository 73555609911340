import React from 'react'
import Layout from '../components/layout'
import ContentWriter from '../components/categories/content-writer'
import Seo from '../components/seo'

const ContentWriterPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ContentWriter />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Content Writer Freelancers | Codestaff'
  const description =
    'Hire the best Content Writer freelancers at Codestaff. Get the top 1% of Content Writer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ContentWriterPage
